export enum ModalType {
  Cancellation = 'CANCELLATION',
  CannotRescheduleGroup = 'CANNOT_RESCHEDULE_GROUP',
}

export enum CloseModalTriggers {
  CloseModal = 'keep-appointment',
  Reschedule = 'reschedule',
}

export type ModalContext = {
  type: ModalType;
  props: any;
};
