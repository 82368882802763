import React, { useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Clock } from '@wix/wix-ui-icons-common/on-stage';
import { ActionsMenuLayout, ButtonPriority } from 'wix-ui-tpa/cssVars';
import { classes as classesActionsMenu } from '../../ManageButton/ManageButton.st.css';
import { classes } from './Reschedule.st.css';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  ActionsMenuType,
  BookingsGroup,
  ModalType,
  RescheduleButtonOrigin,
} from '../../../../../types';
import { ActionsButtonsDataHooks, RescheduleDataHooks } from '../../datahooks';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import Button from '../../../Components/Button';

export interface RescheduleProps extends ActionButtonProps {
  group: BookingsGroup;
  fullWidthButton?: boolean;
  origin: RescheduleButtonOrigin;
}

export const Reschedule: React.FC<RescheduleProps> = ({
  type,
  group,
  fullWidthButton,
  origin,
  onActionClick,
}) => {
  const { actions } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let content;

  const onRescheduleClick = () => {
    if (group?.bookings?.length > 1) {
      actions.showModal(ModalType.CannotRescheduleGroup);
    } else {
      onActionClick?.(ActionsMenuType.RESCHEDULE, {
        showManageButtonLoader: true,
      });
      setLoading(true);
      actions.clickOnReschedule({ group, origin });
    }
  };

  if (type === ActionButtonUiTypes.MENU) {
    content = (
      <div data-hook={ActionsButtonsDataHooks.RescheduleMenu}>
        <ActionsMenuLayout
          className={classesActionsMenu.actionMenuLayout}
          data-hook={ActionsButtonsDataHooks.RescheduleMenu}
        >
          <ActionsMenuLayout.Item
            onClick={onRescheduleClick}
            prefixIcon={<Clock />}
            content={t('app.my-bookings-widget.book-reschedule.label')}
          />
        </ActionsMenuLayout>
      </div>
    );
  }

  if (type === ActionButtonUiTypes.BUTTON) {
    content = (
      <div>
        <Button
          loading={loading}
          data-hook={ActionsButtonsDataHooks.RescheduleButton}
          onClick={onRescheduleClick}
          priority={ButtonPriority.basicSecondary}
          className={classes.rescheduleButton}
          fullWidth={fullWidthButton}
        >
          {t('app.my-bookings-widget.book-reschedule.label')}
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.root} data-hook={RescheduleDataHooks.Main}>
      {content}
    </div>
  );
};
