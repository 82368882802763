export enum WidgetDataHooks {
  MyBookingsMainWidget = 'my-bookings-widget',
}

export enum HeaderDataHooks {
  Main = 'header-main',
  PageTitle = 'page-title',
  PageSubtitle = 'page-subtitle',
}

export enum TimezoneDropdownDataHooks {
  Main = 'timezone-dropdown-main',
  Dropdown = 'timezone-dropdown',
  TimezoneText = 'timezone-text',
}

export enum BookingsLayoutDataHooks {
  Main = 'bookings-layout-main',
  TabbedContent = 'bookings-layout-tabbed-content',
  Spinner = 'bookings-layout-spinner',
}

export enum EmptyStateDataHooks {
  Main = 'empty-state-main',
  UpcomingMain = 'upcoming-empty-state-main',
  HistoryMain = 'history-empty-state-main',
  Text = 'empty-state-title',
  Button = 'empty-state-button',
}

export enum BookingsListDataHooks {
  Main = 'bookings-list-main',
  BookingsDetailsWrapper = 'bookings-list-bookings-details-wrapper',
  Accordion = 'bookings-list-accordion',
  BookingDetails = 'bookings-list-booking-details',
}

export enum BookingDetailsDataHooks {
  Main = 'booking-details-main',
  PendingApprovalBadge = 'booking-details-pending-approval-badge',
  WaitlistBadge = 'booking-details-waitlist-badge',
  ManageButton = 'booking-details-manage-button',
  AvailableOnlineBadge = 'booking-details-available-online-badge',
}

export enum ManageButtonDataHooks {
  Main = 'my-bookings-manage-button-main',
  PopoverWrapper = 'my-bookings-manage-button-popover-wrapper',
  MobileDrawer = 'my-bookings-manage-button-mobile-drawer',
  Button = 'my-bookings-manage-button',
  MobileHeaderContainer = 'my-bookings-manage-button-mobile-header-container',
  MobileHeaderCloseButton = 'my-bookings-manage-button-mobile-header-close-button',
  MobileHeaderTitle = 'my-bookings-manage-button-mobile-header-title',
  MobileHeaderSubtitle = 'my-bookings-manage-button-mobile-header-subtitle',
}

export enum ActionsMenuDataHooks {
  ActionsMenuContainer = 'my-bookings-manage-action-menu-layout-main',
  ManageActionMenuDivider = 'my-bookings-manage-action-menu-divider',
}

export enum ActionsButtonsDataHooks {
  BookAgainButton = 'my-bookings-actions-buttons-book-again-button',
  BookAgainMenu = 'my-bookings-actions-buttons-book-again-menu',
  RescheduleButton = 'my-bookings-actions-buttons-reschedule-button',
  RescheduleMenu = 'my-bookings-actions-buttons-reschedule-menu',
  CancelMenu = 'my-bookings-actions-buttons-cancel-menu',
  ViewMoreDetailsLink = 'my-bookings-actions-buttons-view-more-details-link',
  JoinVideoConferenceLink = 'my-bookings-actions-buttons-join-video-conference-link',
  JoinVideoConferenceLinkCopy = 'my-bookings-actions-buttons-join-video-conference-link-copy',
}

export enum BookAgainDataHooks {
  Main = 'my-bookings-book-again-main',
}

export enum CancelDataHooks {
  Main = 'my-bookings-cancel-main',
}

export enum ExternalActionLayoutItemDataHooks {
  ActionsMenuItem = 'actions-menu-item',
}

export enum ButtonAdapterDataHooks {
  Main = 'button-adapter-main',
  Spinner = 'button-adapter-spinner',
}

export enum ToastDataHooks {
  Main = 'toast-main',
  TpaToast = 'tpa-toast',
}

export enum ModalFacadeDataHooks {
  Main = 'modal-facade-main',
}

export enum CancellationModalDataHooks {
  Main = 'cancellation-modal-main',
  Reschedule = 'cancellation-modal-with-reschedule',
  Cancellation = 'cancellation-modal',
  GroupCancellation = 'group-cancellation-modal',
}

export enum CannotRescheduleGroupModalDataHooks {
  Main = 'cannot-reschedule-group-modal-main-wrapper',
  Modal = 'cannot-reschedule-group-modal-main',
}

export enum RescheduleDataHooks {
  Main = 'my-bookings-reschedule-main',
}

export enum VideoConferenceDataHooks {
  Main = 'my-bookings-join-video-conference-main',
}

export enum ViewMoreDetailsDataHooks {
  Main = 'my-bookings-view-more-details-main',
}

export enum ModalDataHooks {
  Main = 'my-bookings-modal-main',
  Title = 'my-bookings-modal-title',
  Subtitle = 'my-bookings-modal-subtitle',
  PrimaryButton = 'my-bookings-modal-primary-button',
  SecondaryButton = 'my-bookings-modal-secondary-button',
}

export enum BookingBodyContentDataHooks {
  Main = 'booking-body-content-main',
  Row = 'booking-body-content-row',
}

export enum BookingBodyContentRowDataHooks {
  Main = 'booking-body-content-row-main',
  ServiceName = 'booking-body-content-row-service-name',
  Address = 'booking-body-content-row-address',
  EmptyGridColumn = 'booking-body-content-row-empty-grid-column', // TODO: remove when content is added to the third column
}

export enum BookingBodyContentStaffInfoDataHooks {
  Main = 'booking-body-content-staff-info-main',
  Text = 'booking-body-content-staff-info-text',
}

export enum BookingBodyContentServiceNameDataHooks {
  Main = 'booking-body-content-service-name-main',
  Text = 'booking-body-content-service-name-text',
}

export enum BookingBodyContentDurationDataHooks {
  Main = 'booking-body-content-duration-main',
  Text = 'booking-body-content-duration-text',
}

export enum BookingBodyContentParticipantInfoDataHooks {
  Main = 'booking-body-content-participant-info-main',
  Text = 'booking-body-content-participant-info-text',
}

export enum LimitIndicatorDataHooks {
  Main = 'limit-indicator-main',
  SectionNotification = 'limit-indicator-section-notification',
}
